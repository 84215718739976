<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
    >
      <div class="container">
        <a class="navbar-brand nav" href="https://tasnim.uz/"
          ><img src="../assets/logo_site.svg"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <!--  -->
            <li>
              <a href="/" class="nav-link">Bosh sahifa</a>
            </li>
            <!--  -->
            <li>
              <a href="#about" class="nav-link">Biz haqimizda</a>
            </li>
            <li>
              <a href="#contact" class="nav-link">Aloqa</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
.nav img {
  height: 40px;
  width: 150px;
}
.nav-link {
  font-size: 20px;
  color: #0a5d5b !important;
}
.nav-link:hover {
  color: #10bbb8;
  text-decoration: underline;
}
</style>
