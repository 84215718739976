<template>
  <div class="contact_part" id="contact">
    <div class="container my-5">
      <div class="blend"></div>

      <MessageSuccess v-if="success" />
      <h1 class="text-center">Kursga yozilish.</h1>
      <div class="row">
        <div class="col-12">
          <form @submit.prevent>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Ism</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Ismingizni kiriting"
                v-model="data.name"
              />
              <p
                class="form-error-msg text-danger"
                v-if="nameN"
                :class="nameIsvalid ? 'd-none' : ''"
              >
                Ismingizni yozing....
              </p>
            </div>
            <!--  -->
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Telefon raqami</label
              >
              <input
                class="form-control"
                placeholder="00 000 00 00 Raqamingizni kiriting"
                v-model="data.phone"
                v-mask="'## ### ## ##'"
              />
              <p
                class="form-error-msg text-danger"
                v-if="nameP"
                :class="phoneIsvalid ? 'd-none' : ''"
              >
                Telefon raqamingizni yozing...
              </p>
            </div>
            <!--  -->
            <button class="btn btn-warning" @click="submit">
              Jo'natish
            </button>
          </form>
        </div>
      </div>
    </div>
    <b></b>
  </div>
</template>

<script>
import MessageSuccess from './MessageSuccess.vue';

export default {
  components: {
    MessageSuccess,
  },

  data() {
    return {
      data: {
        name: null,
        phone: null,
      },
      token: '7161883319:AAHAJMrOwvo382hL_1fhgrmcFrK4RZlyPnk',
      myId: '2039796597',
      success: false,
      nameN: false,
      nameP: false,
    };
  },
  computed: {
    nameIsvalid() {
      return !!this.data.name;
    },
    phoneIsvalid() {
      return !!this.data.phone;
    },

    formIsValid() {
      this.nameIsvalid && this.phoneIsvalid;
    },
  },
  methods: {
    submit() {
      if (this.data.name == null) {
        this.nameN = true;
      } else if (this.data.phone == null) {
        this.nameP = true;
      } else {
        let data = `<b>Name:</b> ${this.data.name}
<b>Phone:</b> ${this.data.phone}`;

        this.axios
          .post(
            'https://api.telegram.org/bot' +
              this.token +
              '/sendMessage',
            {
              chat_id: this.myId,
              parse_mode: 'html',
              text: data,
            }
          )
          .then((res) => {
            if (res.data.ok) {
              this.success = true;
              this.data = {
                name: '',
                phone: '',
              };
              setTimeout(() => {
                this.success = false;
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.contact_part {
  background-color: rgba(0, 0, 0, 0.382);
  height: 100%;
  padding: 10px;
}
label {
  color: white;
}
button {
  color: #030303;
  background: #a9a9a9 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
button:hover {
  text-decoration: underline;
}
</style>
