<template>
   <div>
      <Home />

        <div class="container">
          
          <About />
        </div>
          <Contact />

   </div>
</template>

<script>
import Home from "@/components/Home.vue"
import About from "@/components/About.vue"                          
import Contact from "@/components/Contact.vue"








export default {
  components: {
  Home,
  About,
  Contact,

  }
}
</script>