<template>
  <div class="home">
    <div class="home_wrapper">

      <div class="blend"></div>
        <h1>
          <span>Foundation Kids</span> <br>  Bolalar uchun kompyuter kurslari
        </h1>
        <!-- as -->

        <div class="contact">
            <a href="tel:+998881533303" class="phone"><i class="bi bi-telephone-outbound-fill"></i>Aloqaga chiqish</a>

            <a href="https://t.me/Tasnim_admin" class="telegram"><i class="bi bi-telegram"></i>Telegram</a>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .home{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #0c7978; */
    background-image: url(../assets/homme.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  .blend{
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.76);
    z-index: -50;
  }
  .home_wrapper{
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .home_wrapper h1{ 
    text-align: center;
    font-size: 50px;
    /* color: #117f7d !important; */
  }
  .contact{
    width: 30%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  .contact a{
    margin: 0 5px;
    text-decoration: none;
    font-size: 25px;
    /* border: 1px solid red; */
    
    height: 50px;
    background-color: white !important;
    padding: 10px;
    border-radius: 5px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .phone{
    width: 210px;
    color: rgb(7, 206, 10);
    white-space: nowrap;
  }
  .telegram{
    width: 150px;
    color: rgb(0, 153, 255);
  }
  .telegram:hover{
    text-decoration: underline !important;
  }
   .phone:hover{
    text-decoration: underline !important;
  }

  /*  */
  @media(max-width: 1024px) {
    .contact{
      justify-content: center;
    }
  }
</style>