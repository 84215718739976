<template>
    <div id="about">
        <div class="row my-5 justify-content-center align-items-center" >
            <div class="col-11 col-sm-10 col-md-4">
                <img src="../assets/aboutp.png" class="img-fluid about_img" alt="">
            </div>
            <div class="col-11 col-sm-10 col-md-8">
                <h2>
                    Bolalar Uchun Kompyuter Kurslari
                </h2>
                <p class="clamp">
                    Tasnim akademiyasi tomonidan bolalar uchun kompyuter kurslari . Yosh bolalarni kompyuterga va zamonaviy kasblarga qiziqshini rivojlantirish maqsadida Tasnim Akademiyasi Foundation kids kurslari uchun qabulini boshlaydi. O'quvchilar kurslar davomida Word , Excel va Powerpointda ishlash . Canvadan erkin foydalanish , suniy intelekt haqida boshlang'ich tushuncha , GPT yordamida creativ loyixalar tayyorlash Scratch platformasida sodda sistemali o'yinlar yaratish va ularni takomillashtirish va shu kabi boshqa IT soxasini kerakli qismlari bo'yicha tushunchalarga ega bo'lishadi. 
                </p>
                
            </div>
                
        </div>
    </div>
</template>

<script>
import data from '@/data.json';
export default {
    data() {
        return {
            about: data.about,
        }
    }
}
</script>

<style scoped>

    p{
        font-size: 20px;
        text-align: justify;
    }
</style>



